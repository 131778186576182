import { fetchBlog } from '../../util/api';
import { getStrapiLocale } from '../../util/genericHelpers';

const defaultLocale = getStrapiLocale() || 'el-GR';

// ================ Action types ================ //

export const SEARCH_BLOGS_REQUEST = 'app/SingleBlogPage/SEARCH_BLOGS_REQUEST';
export const SEARCH_BLOGS_SUCCESS = 'app/SingleBlogPage/SEARCH_BLOGS_SUCCESS';
export const SEARCH_BLOGS_ERROR = 'app/SingleBlogPage/SEARCH_BLOGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  blogData: [],
  searchBlogsError: null,
};

const blogsPageReducers = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_BLOGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        blogData: payload?.data || [],
      };
    case SEARCH_BLOGS_ERROR:
      return { ...state, searchInProgress: false, searchBlogsError: true };
    default:
      return state;
  }
};

export default blogsPageReducers;

// ================ Action creators ================ //

export const searchBlogsRequest = () => ({
  type: SEARCH_BLOGS_REQUEST,
});

export const searchBlogsSuccess = response => ({
  type: SEARCH_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchBlogsError = () => ({
  type: SEARCH_BLOGS_ERROR,
});

export const fetchSingleBlog = params => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());
  try {
    const response = await fetchBlog({ ...params, defaultLocale });

    dispatch(searchBlogsSuccess(response?.blog?.data));
  } catch (error) {
    dispatch(searchBlogsError());
  }
};

export const loadData = (params, search) => {
  const { id = 1 } = params;
  return fetchSingleBlog({ id });
};
