export const PROVIDER = {
  LICENSE: 'license',
  RESUME: 'resume',
  DOCUMENTS: 'documents',
  SPECIALITY: 'speciality',
};

export const SENDGRID_CONTRACT_LIST = {
  ALL_PATIENTS: '6a86af51-68fc-4ecd-9ba0-bec2678673d2',
  BAYER_AND_JDE: 'b1d903ff-ae8e-440d-8299-ebf80a09ecb0',
  FREE_TRIAL: 'b1230b55-d2ab-4248-a8fc-5de2c70d1523',
};

export const MEETING_PANEL = {
  KICK: 'kick',
  LEAVE: 'leave',
};
export const StatusCode = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
});

export const COMMUNITY_FACEBOOK_LINK = 'https://www.facebook.com/getvivianlab';

export const DEFAULT_PRODUCT_NAME =
  'Βασικό ποσό: €100, ΦΠΑ (24%): €24, Συνολικό ποσό (συμπεριλαμβανομένου ΦΠΑ): €124';

export const USERTYPES = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};

export const recipeOptions = {
  el: {
    Recipes: 'Συνταγές',
    'Nutrition plans': 'Πλάνα διατροφής',
    'Mindfulness techniques': 'Τεχνικές Mindfulness',
    Exercise: 'Άσκηση',
  },
  de: {
    Recipes: 'Rezepte',
    'Nutrition plans': 'Ernährungspläne',
    'Mindfulness techniques': 'Achtsamkeitstechniken',
    Exercise: 'Übung',
  },
  en: {
    Recipes: 'Recipes',
    'Nutrition plans': 'Nutrition plans',
    'Mindfulness techniques': 'Mindfulness techniques',
    Exercise: 'Exercise',
  },
};

export const getTranslatedRecipe = (key, ln) => {
  return recipeOptions[ln]?.[key] || key; // Return the translated word or default to the key if not found
};

export const EnvBool = {
  TRUE: 'true',
  FALSE: 'false',
};

export const RenderChatFeedKey = Object.freeze({
  STREAMING: 'streaming',
  AI: 'ai',
  YOUTUBE: 'youtube',
  DEFAULT: 'default',
});
