import { useCallback, useRef, useState } from 'react';
import { useConfiguration } from '../context/configurationContext';
import CryptoJS from 'crypto-js';
import { getTranslatedRecipe, receipeOptions } from './enums';

export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr?.length > 0 ?? false);
};

export const getDefaultLocale = () => {
  const hasEnglishLocale = typeof window !== 'undefined' && location?.pathname?.includes('/en');

  if (hasEnglishLocale && typeof window !== 'undefined') {
    return 'en';
  }

  const defaultLocale = typeof window !== 'undefined' && window.localStorage.getItem('locale');

  return defaultLocale || 'el';
};

export const isLocaleGreek = () => {
  const config = useConfiguration();
  const locale = config.localization.locale;
  return locale === 'el';
};

export const onSendEmail = (body = null) => {
  const isValidBody = typeof body === 'string';

  if (typeof window !== 'undefined') {
    const email = 'info@vivianlab.com';
    const encodedBody = isValidBody && encodeURIComponent(body);
    const messageWithBody = isValidBody
      ? `mailto:${email}?subject=Contact VivianLab&body=${encodedBody}`
      : `mailto:${email}`;
    window.location.href = messageWithBody;
  }
};

// Encryption function
export const encrypt = (plaintext, secretKey) => {
  const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
  return ciphertext;
};

// Decryption function
export const decrypt = (ciphertext, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const isImageLink = link => {
  // Supported image file extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'heic', 'heif'];

  // Get the file extension from the link
  const extension = link
    .split('.')
    .pop()
    .toLowerCase();

  // Check if the extension matches any of the image extensions
  return imageExtensions.includes(extension);
};

export const appendAverageReviews = reviews => {
  const MIN_RATING = 1;
  const MAX_RATING = 5;

  const filteredReviews =
    Array.isArray(reviews) &&
    reviews.filter(r => r?.attributes?.rating !== null && r?.attributes?.state === 'public');

  const totalReviews = filteredReviews?.length || 0;

  if (!totalReviews) {
    return null;
  }

  const ratings = filteredReviews.map(review => review?.attributes?.rating);
  const validRatings = ratings.filter(r => r >= MIN_RATING && r <= MAX_RATING);

  if (!validRatings.length) {
    return null;
  }

  const starSum = validRatings.reduce((partialSum, rating) => partialSum + rating, 0);
  const averageRating = starSum / validRatings.length;

  return averageRating && averageRating.toFixed(1);
};

export const getPreferredLanguage = user => {
  return user?.attributes?.profile?.protectedData?.preferredLang || 'gr';
};

export const sortArrayAlphabetically = array => {
  // Clone the original array to avoid modifying the original data
  const sortedArray = [...array];

  // Sort the array alphabetically by the "value" property
  sortedArray.sort((a, b) => a.label.localeCompare(b.label));

  return sortedArray;
};

export const sortGreekLabels = data => {
  return data.sort((a, b) => new Intl.Collator('el').compare(a.label, b.label));
};

export const sortGreekCountriesLabels = data => {
  return data.sort((a, b) => new Intl.Collator('el').compare(a.name, b.name));
};

export const removeOfflineOption = services => {
  // Map through each service in the array
  return services.map(service => {
    // Filter out the 'offline' option from the bookingOptions array
    const updatedBookingOptions = service.bookingOptions.filter(option => option !== 'Offline');

    // Return the modified service object with the updated bookingOptions
    return {
      ...service,
      bookingOptions: updatedBookingOptions,
    };
  });
};

export const calculateSubscriptionPrice = ({ price: priceStr, percentageOff, currency }) => {
  // Extract the numerical part of the price from the string
  const price = !!priceStr && parseFloat(priceStr.replace(/[^\d.-]/g, ''));

  // Calculate the discount amount
  const discount = (price * percentageOff) / 100;

  // Calculate the final price
  const finalPrice = price - discount;

  // Format the price with two decimal places for better precision
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(finalPrice);

  return { finalPrice: formattedPrice, priceAmount: parseFloat(finalPrice.toFixed(2)) };
};

export const sanitizeString = value => {
  if (typeof value === 'string') {
    return value.replace('[object Object]', '').trim();
  }
  return value;
};

const shuffleImages = imagePaths => {
  let shuffled = imagePaths;
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export const getUniqueImages = (index, imagePaths) => {
  let images = shuffleImages(imagePaths);

  // Use the index directly for more custom logic
  // For example: Ensure no image repeats more than 4 times
  if (index % 4 === 0 && index !== 0) {
    // Shuffle again after every 4 images
    images = shuffleImages(imagePaths);
  }

  // Return the image based on index
  return images[index % images.length];
};

export const parseUrlToOriginal = search => {
  // Replace hyphens with spaces and capitalize each word
  return search
    ?.split('-')
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(' ');
};

export const getLastUpdatedTime = items => {
  if (!items.length) return 'No updates available';

  // Filter out items with invalid or missing `updatedAt` dates
  const validItems = items.filter(
    item => item?.attributes?.updatedAt && !isNaN(new Date(item?.attributes?.updatedAt))
  );

  if (!validItems.length) return 'No valid updates available';

  // Find the most recent `updatedAt` timestamp
  const mostRecent = validItems.reduce((latest, item) => {
    return new Date(item?.attributes?.updatedAt) > new Date(latest?.attributes?.updatedAt)
      ? item
      : latest;
  });

  // Calculate the time difference
  const currentTime = new Date();
  const updatedAtTime = new Date(mostRecent?.attributes?.updatedAt);
  const diffMs = currentTime - updatedAtTime;

  // Convert milliseconds to hours and minutes
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  // Return formatted result
  if (diffHours > 0) {
    return `Updated ${diffHours} hour(s) ago`;
  } else {
    return `Updated ${diffMinutes} minute(s) ago`;
  }
};

/**
 * Function to filter events based on voucher existence and user eligibility
 * @param {Array} events - List of events
 * @param {string} ownVoucher - User's voucher
 * @returns {Array} - Filtered list of events
 */
export const createEventFilter = (events, ownVoucher) => {
  return events.filter(event => {
    const eventVoucherName =
      event?.attributes?.voucher?.data?.attributes?.company?.toLowerCase() || '';
    const isGroupEvent = event?.attributes?.isGroup || false;
    const normalizedOwnVoucher = ownVoucher?.toLowerCase() || '';

    // Case 1: If the user has a subscription (ownVoucher exists), they should always see the event
    if (normalizedOwnVoucher) {
      return true;
    }

    // Case 2: Allow all group events to be visible
    if (isGroupEvent) {
      return true;
    }

    // Case 3: Event has a voucher - match user's voucher
    if (eventVoucherName) {
      // Exact match or inclusion check (both lowercase)
      if (
        normalizedOwnVoucher === eventVoucherName ||
        normalizedOwnVoucher.includes(eventVoucherName)
      ) {
        return true;
      }

      // JDESESSIONS: Ensure only JDE users see JDE sessions
      if (eventVoucherName === 'jde' && normalizedOwnVoucher !== 'jde') {
        return false;
      }

      // Bayer users shouldn't see JDE-only events
      if (eventVoucherName === 'jde' && normalizedOwnVoucher === 'bayer') {
        return false;
      }

      return false; // Hide if no conditions matched
    }

    // Case 4: Show events without a voucher to all users
    return true;
  });
};

export const filterEventsWithoutCompanyVoucher = events => {
  return events?.filter(e => !e?.attributes?.voucher?.data?.attributes?.company);
};

export const getStrapiLocale = () => {
  const defaultLocale = getDefaultLocale();
  return defaultLocale === 'el' ? 'el-GR' : defaultLocale;
};

// Function to get options by language
export const getOptionsByLanguage = (locale, uniqueTypes) => {
  return uniqueTypes.map(type => getTranslatedRecipe(type, locale));
};

export const getQuestionsByLang = lang => {
  const greekQuestions = [
    {
      id: 'AIChatPage.defaultQuestion1',
      message: 'τι είναι η θεραπεία ορμονικής υποκατάστασης;',
    },
    {
      id: 'AIChatPage.defaultQuestion2',
      message: 'ποιες προτάσεις δίνονται για την παρακολούθηση των συμπτωμάτων;',
    },
    {
      id: 'AIChatPage.defaultQuestion3',
      message: 'ποιες ομάδες τροφίμων είναι σημαντικές για την ενίσχυση της σεξουαλικής διάθεσης;',
    },
    {
      id: 'AIChatPage.defaultQuestion4',
      message: 'αν πιω αλκοόλ θα μπορέσω να κοιμηθώ πιο εύκολα;',
    },
  ];

  const englishQuestions = [
    {
      id: 'AIChatPage.defaultQuestion1',
      message: 'What is Hormone Replacement Therapy?',
    },
    {
      id: 'AIChatPage.defaultQuestion2',
      message: 'What causes mood swings in menopause?',
    },
    {
      id: 'AIChatPage.defaultQuestion3',
      message: 'What causes hot flashes?',
    },
    {
      id: 'AIChatPage.defaultQuestion4',
      message: 'Will I gain weight if I don’t sleep well?',
    },
  ];

  return lang === 'el' ? greekQuestions : englishQuestions;
};

export const sortRecurringEventsMaybe = events => {
  const now = new Date().toISOString();

  return events.sort((a, b) => {
    const getNextValidDate = event => {
      if (event.recurringDates?.length) {
        // Get future recurring dates
        const futureDates = event.recurringDates
          .map(d => new Date(d.Date))
          .filter(d => d >= new Date(now))
          .sort((d1, d2) => d1 - d2);
        return futureDates.length ? futureDates[0] : new Date(event.Date); // Use main date if no future recurring
      }
      return new Date(event.Date); // No recurring dates, use main event date
    };

    const dateA = getNextValidDate(a);
    const dateB = getNextValidDate(b);

    return dateA - dateB;
  });
};

export const sortWebinarEventsByDate = events => {
  if (!Array.isArray(events)) return [];
  
  const currentTime = Date.now();
  
  return events
    .map(event => {
      if (!event || !event.attributes || !event.attributes.date) return null;
      
      let baseDate = new Date(event.attributes.date).getTime();
      let allDates = new Set([baseDate]); // Using Set to avoid duplicate timestamps

      // Add recurring dates if they exist
      if (event.attributes.recurringDates && Array.isArray(event.attributes.recurringDates)) {
        event.attributes.recurringDates.forEach(rd => {
          if (rd && rd.Date) {
            allDates.add(new Date(rd.Date).getTime());
          }
        });
      }

      const allDatesSorted = Array.from(allDates).sort((a, b) => a - b);
      return {
        ...event,
        allDates: allDatesSorted,
        earliestDate: Math.min(...allDatesSorted),
        latestDate: Math.max(...allDatesSorted)
      };
    })
    .filter(event => event !== null && event.latestDate >= currentTime) // Remove past events
    .sort((a, b) => a.earliestDate - b.earliestDate); // Sort by earliest date
};