import React, { useEffect, useState } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import {
  sendVerificationEmail,
  hasCurrentUserErrors,
  fetchTodaysAvailability,
  getStripeUserInfo,
} from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { NamedRedirect, Topbar } from '../../components';
import { isCorporateCustomer, isDoctorApproved, isIndividualUser } from '../../util/dataExtractors';
import { useIntl } from '../../util/reactIntl';
import Cookies from 'js-cookie';

import css from './TopbarContainer.module.css';
import moment from 'moment';
import { queryOwnListings } from '../ManageListingsPage/ManageListingsPage.duck';
import { isArrayLength } from '../../util/genericHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    isHeaderSticky,
    staticPageHeader,
    currentUserHasPendingListings,
    isPostLandingPage = false,
    hideVerificationModal = false,
    showAvailabilityModal,
    dashboardHeader,
    isFullWidthHeader,
    ...rest
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const isCustomer = isIndividualUser(currentUser);
  const isValidCorporateCustomer = isCorporateCustomer(currentUser);

  const approvalContent = intl.formatMessage({
    id: 'LandingPage.approvalContent',
  });

  const [isModalClosedToday, setIsModalClosedToday] = useState(false);

  const [ownListing, setOwnListing] = useState(false);

  const fetchOwnListings = async () => {
    if (isAuthenticated) {
      const ownListings = await dispatch(queryOwnListings());
      setOwnListing(isArrayLength(ownListings) && ownListings[0]);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const initialize = async () => {
      if (!isCustomer) {
        dispatch(fetchTodaysAvailability({}));
      }

      const lastModalInteractionDate = Cookies.get('lastModalInteractionDate');
      const today = moment().startOf('day');
      const lastInteractionDay = lastModalInteractionDate
        ? moment(lastModalInteractionDate).startOf('day')
        : null;

      setIsModalClosedToday(lastInteractionDay && today.isSame(lastInteractionDay));

      if (currentUser?.stripeAccount?.attributes?.stripeAccountId) {
        dispatch(
          getStripeUserInfo({ stripeId: currentUser.stripeAccount.attributes.stripeAccountId })
        );
      }

      if (location.pathname === '/') {
        const redirectPath = createResourceLocatorString('Homepage', routeConfiguration(), {}, {});
        history.push(redirectPath);
      }

      await fetchOwnListings();
    };

    initialize();
  }, [isAuthenticated, currentUser, location.pathname]);

  const handleDoItLater = () => {
    // Set the cookie to track the interaction date
    Cookies.set('lastModalInteractionDate', moment().toDate(), { expires: 1 });

    // Update the state to reflect that the modal has been closed today
    setIsModalClosedToday(true);
  };

  useEffect(() => {
    if (!isAuthenticated || !currentUser) return;

    const stripeId = currentUser?.stripeAccount?.attributes?.stripeAccountId;

    if (stripeId) {
      dispatch(getStripeUserInfo({ stripeId }));
    }

    if (location.pathname === '/') {
      navigateToHomepage();
    }

    // Utility function for navigation
    function navigateToHomepage() {
      const redirectPath = createResourceLocatorString('Homepage', routeConfiguration(), {}, {});
      history.push(redirectPath);
    }
  }, [isAuthenticated, currentUser?.stripeAccount?.attributes?.stripeAccountId, location.pathname]);

  return (
    <>
      <Topbar
        authInProgress={authInProgress}
        currentPage={currentPage}
        currentSearchParams={currentSearchParams}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        history={history}
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        location={location}
        ownListing={ownListing}
        isModalClosedToday={isModalClosedToday}
        handleDoItLater={handleDoItLater}
        showAvailabilityModal={showAvailabilityModal}
        notificationCount={notificationCount}
        onLogout={onLogout}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        showGenericError={hasGenericError}
        isHeaderSticky={isHeaderSticky}
        staticPageHeader={staticPageHeader}
        hideVerificationModal={hideVerificationModal}
        dashboardHeader={dashboardHeader}
        isFullWidthHeader={isFullWidthHeader}
        {...rest}
      />
      {isPostLandingPage &&
      isAuthenticated &&
      !isDoctorApproved(currentUser) &&
      currentUserHasPendingListings ? (
        <div className={css.profileVerification}>{approvalContent}</div>
      ) : null}
    </>
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasPendingListings,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showAvailabilityModal,
  } = state.user;

  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasPendingListings,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    showAvailabilityModal,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
