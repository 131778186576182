import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { createClient } from '@supabase/supabase-js';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isArrayLength } from '../../util/genericHelpers';
import { v4 as uuidv4 } from 'uuid'; // UUID generator
import { search, streamAIChat } from '../../util/api';
import { handleChatSessionUpdate } from '../../util/chatHelpers';

const supabase = createClient(
  process.env.REACT_APP_PUBLIC_SUPABASE_URL,
  process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY
);

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AIChatPage/SET_INITIAL_VALUES';

export const INITIATE_CHAT_SESSION_REQUEST = 'app/AIChatPage/INITIATE_CHAT_SESSION_REQUEST';
export const INITIATE_CHAT_SESSION_SUCCESS = 'app/AIChatPage/INITIATE_CHAT_SESSION_SUCCESS';
export const INITIATE_CHAT_SESSION_ERROR = 'app/AIChatPage/INITIATE_CHAT_SESSION_ERROR';

export const FETCH_CHAT_SESSION_REQUEST = 'app/AIChatPage/FETCH_CHAT_SESSION_REQUEST';
export const FETCH_CHAT_SESSION_SUCCESS = 'app/AIChatPage/FETCH_CHAT_SESSION_SUCCESS';
export const FETCH_CHAT_SESSION_ERROR = 'app/AIChatPage/FETCH_CHAT_SESSION_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/AIChatPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/AIChatPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/AIChatPage/SEND_INQUIRY_ERROR';
export const UPDATE_AI_CHAT_STREAM = 'app/AIChatPage/UPDATE_AI_CHAT_STREAM';
export const UPDATE_AI_CHAT_STREAM_SUCCESS = 'app/AIChatPage/UPDATE_AI_CHAT_STREAM_SUCCESS';
export const CLEAR_AI_CHAT_STREAM = 'app/AIChatPage/CLEAR_AI_CHAT_STREAM';

export const FETCH_PREVIOUS_MESSAGE_REQUEST = 'app/AIChatPage/FETCH_PREVIOUS_MESSAGE_REQUEST';
export const FETCH_PREVIOUS_MESSAGE_SUCCESS = 'app/AIChatPage/FETCH_PREVIOUS_MESSAGE_SUCCESS';
export const FETCH_PREVIOUS_MESSAGE_ERROR = 'app/AIChatPage/FETCH_PREVIOUS_MESSAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendInquiryInProgress: false,
  sendInquiryError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  messages: [],
  initiateChatSessionInProgress: false,
  initiateChatSessionError: null,
  fetchChatSessionInProgress: false,
  fetchChatSessionError: null,
  chatSession: [],
  streamContent: '',
  isChatStreaming: false,
  userMessage: '',
};

const AIChatPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    // Initiate Chat Session Cases
    case INITIATE_CHAT_SESSION_REQUEST:
      return {
        ...state,
        initiateChatSessionInProgress: true,
        initiateChatSessionError: null,
      };

    case INITIATE_CHAT_SESSION_SUCCESS:
      return {
        ...state,
        initiateChatSessionInProgress: false,
        // Add any additional handling for success if needed
      };
    case INITIATE_CHAT_SESSION_ERROR:
      return {
        ...state,
        initiateChatSessionInProgress: false,
        initiateChatSessionError: payload,
      };
    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return {
        ...state,
        sendInquiryInProgress: false,
        sendInquiryError: null,
      };
    case UPDATE_AI_CHAT_STREAM:
      return {
        ...state,
        isChatStreaming: true,
        streamContent: state.streamContent + payload,
      };
    case UPDATE_AI_CHAT_STREAM_SUCCESS:
      return {
        ...state,
        isChatStreaming: false,
        userMessage: payload,
      };
    case CLEAR_AI_CHAT_STREAM:
      return {
        ...state,
        streamContent: '',
      };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };
    case FETCH_PREVIOUS_MESSAGE_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_PREVIOUS_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: payload,
        fetchMessagesError: null,
      };
    case FETCH_PREVIOUS_MESSAGE_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };
    case FETCH_CHAT_SESSION_REQUEST:
      return { ...state, fetchChatSessionInProgress: true, fetchChatSessionError: null };
    case FETCH_CHAT_SESSION_SUCCESS:
      return {
        ...state,
        fetchChatSessionInProgress: false,
        chatSession: payload,
        fetchChatSessionError: null,
      };
    case FETCH_CHAT_SESSION_ERROR:
      return { ...state, fetchChatSessionInProgress: false, fetchChatSessionError: payload };

    default:
      return state;
  }
};

export default AIChatPageReducer;

// ================ Selectors ================ //
export const messagesSelector = state => {
  const { messages, fetchMessagesInProgress, fetchMessagesError } = state.AIChatPage;
  return {
    messages,
    fetchMessagesInProgress,
    fetchMessagesError,
  };
};
export const chatSessionSelector = state => {
  const { chatSession, fetchChatSessionInProgress, fetchChatSessionError } = state.AIChatPage;
  return {
    chatSession,
    fetchChatSessionInProgress,
    fetchChatSessionError,
  };
};
export const sendEnquirySelector = state => {
  const { sendInquiryInProgress, sendInquiryError } = state.AIChatPage;
  return {
    sendInquiryInProgress,
    sendInquiryError,
  };
};
export const streamContentSelector = state => state.AIChatPage.streamContent;

export const isChatStreamingSelector = state => state.AIChatPage.isChatStreaming;
export const userMessageSelector = state => state.AIChatPage.userMessage;
// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const initiateChatSessionRequest = () => ({
  type: INITIATE_CHAT_SESSION_REQUEST,
});

export const initiateChatSessionSuccess = () => ({
  type: INITIATE_CHAT_SESSION_SUCCESS,
  // You can include a payload here if needed
});

export const initiateChatSessionError = error => ({
  type: INITIATE_CHAT_SESSION_ERROR,
  error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = response => ({ type: SEND_INQUIRY_SUCCESS, payload: response });

export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });
export const updateAIChatStream = data => ({ type: UPDATE_AI_CHAT_STREAM, payload: data });
export const updateAIChatStreamSuccess = message => ({
  type: UPDATE_AI_CHAT_STREAM_SUCCESS,
  payload: message,
});
export const clearAIChatStream = () => ({
  type: CLEAR_AI_CHAT_STREAM,
});

export const fetchPreviousMessagesRequest = () => ({ type: FETCH_PREVIOUS_MESSAGE_REQUEST });
export const fetchPreviousMessagesSuccess = response => ({
  type: FETCH_PREVIOUS_MESSAGE_SUCCESS,
  payload: response,
});
export const fetchPreviousMessagesError = e => ({
  type: FETCH_PREVIOUS_MESSAGE_ERROR,
  error: true,
  payload: e,
});
export const fetchChatSessionRequest = () => ({ type: FETCH_CHAT_SESSION_REQUEST });
export const fetchChatSessionSuccess = response => ({
  type: FETCH_CHAT_SESSION_SUCCESS,
  payload: response,
});
export const fetchChatSessionError = e => ({
  type: FETCH_CHAT_SESSION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendInquiry = params => async (dispatch, getState, sdk) => {
  const { message, chatSessionId, language } = params || {};
  dispatch(sendInquiryRequest());
  const currentUser = getState().user.currentUser;
  const currentUserId = currentUser ? currentUser?.id?.uuid : uuidv4();

  if (!currentUserId) {
    console.error('Error: User ID is missing.');
    dispatch(sendInquiryError(new Error('User is not authenticated.')));
    return;
  }

  try {
    const response = await search({ message, language });

    // Fetch the current chat messages
    const { data: chatSessionData, error: fetchError } = await supabase
      .from('chatsessions')
      .select('chat_messages')
      .eq('id', chatSessionId)
      .single();

    if (fetchError) {
      throw new Error(`Failed to fetch chat session: ${fetchError.message}`);
    }

    // Prepare new chat message
    const chatMessage = {
      message_id: uuidv4(),
      message,
      createdAt: new Date().toISOString(),
      chat_reference_id: chatSessionId,
    };

    const updatedChatMessages = chatSessionData?.chat_messages || [];
    updatedChatMessages.push(chatMessage);

    // Determine question and answer
    const question = response?.question || message;
    const answer = response?.answer || 'No result Found';
    const tags = response?.tags || null;

    // Call the updated handleChatSessionUpdate
    await handleChatSessionUpdate({
      chatSessionId,
      updatedChatMessages,
      chatMessageId: chatMessage.message_id,
      currentUserId,
      question,
      answer,
      tags,
      supabase,
    });

    // Fetch updated session data
    const { data: chatSessionResponse, error: fetchUpdatedError } = await supabase
      .from('chatsessions')
      .select('*')
      .eq('id', chatSessionId)
      .single();

    if (fetchUpdatedError) {
      console.log(fetchUpdatedError, 'fetchUpdatedErrorfetchUpdatedError');
      throw new Error(`Failed to fetch updated chat session: ${fetchUpdatedError.message}`);
    }
    dispatch(sendInquirySuccess());
    dispatch(fetchPreviousMessages([chatSessionResponse]));
  } catch (error) {
    console.error('Error during send inquiry:', error);
    dispatch(sendInquiryError(storableError(error)));
  }
};

// Thunk to stream AI chat response and update Redux state chunk-by-chunk
export const sendChatStreamInquiry = params => async (dispatch, getState, sdk) => {
  const { message, chatSessionId } = params || {};
  dispatch(clearAIChatStream());

  const currentUser = getState().user.currentUser;
  const currentUserId = currentUser ? currentUser.id?.uuid : uuidv4();

  if (!currentUserId) {
    console.error('Error: User ID is missing.');
    dispatch(sendInquiryError(new Error('User is not authenticated.')));
    return;
  }

  try {
    // streamAIChat should return an async iterator of plain-text chunks
    const stream = await streamAIChat({ message });

    // Consume each text chunk from the stream
    for await (const chunk of stream) {
      dispatch(updateAIChatStream(chunk));
      // Wait for the next animation frame before processing the next chunk
      await new Promise(resolve => requestAnimationFrame(resolve));
    }

    dispatch(updateAIChatStreamSuccess(message));

    const streamContent = getState().AIChatPage.streamContent;
    // Fetch the current chat messages
    const { data: chatSessionData, error: fetchError } = await supabase
      .from('chatsessions')
      .select('chat_messages')
      .eq('id', chatSessionId)
      .single();

    if (fetchError) {
      throw new Error(`Failed to fetch chat session: ${fetchError.message}`);
    }

    // Prepare new chat message
    const chatMessage = {
      message_id: uuidv4(),
      message,
      createdAt: new Date().toISOString(),
      chat_reference_id: chatSessionId,
      isAIChatEnabled: true,
    };

    const updatedChatMessages = chatSessionData?.chat_messages || [];
    updatedChatMessages.push(chatMessage);

    // Determine question and answer
    const question = message;
    const answer = streamContent || 'No result Found';

    // Call the updated handleChatSessionUpdate
    await handleChatSessionUpdate({
      chatSessionId,
      updatedChatMessages,
      chatMessageId: chatMessage.message_id,
      currentUserId,
      question,
      answer,
      supabase,
    });
  } catch (error) {
    console.error('Error during send inquiry:', error);
    dispatch(sendInquiryError(error));
  }
};
// Helper function for loadData call.
export const fetchPreviousMessages = params => async (dispatch, getState, sdk) => {
  dispatch(fetchPreviousMessagesRequest());

  try {
    const sessions = Array.isArray(params) ? params : []; // Ensure params is an array

    const processSessionMessages = async session => {
      const chatMessages = (session.chat_messages || [])
        .map(message => {
          try {
            return JSON.parse(message);
          } catch (error) {
            console.warn('Failed to parse message:', message, error);
            return null; // Skip invalid messages
          }
        })
        .filter(message => message); // Filter out null messages
      // Extract chat_reference_ids to fetch related data from AIQuestionire
      const referenceIds = chatMessages
        .filter(message => message.message_id)
        .map(message => message.message_id.trim());

      // Convert referenceIds to UUID format (if needed)
      const validReferenceIds = referenceIds.map(id => id);

      let aiQuestionireData = [];

      if (validReferenceIds.length > 0) {
        const { data: aiData, error: aiFetchError } = await supabase
          .from('aiquestionire')
          .select('*')
          .in('chat_reference_id', validReferenceIds);

        if (aiFetchError) {
          console.error('Error fetching AIQuestionire data:', aiFetchError);
          throw aiFetchError;
        }

        aiQuestionireData = aiData;
      }

      // Combine fetched AIQuestionire data with chat messages
      const combinedMessages = chatMessages.map(message => {
        if (message.message_id) {
          const referenceData = aiQuestionireData.find(
            aiItem => aiItem.chat_reference_id === message.message_id.trim()
          );
          return { ...message, referenceData };
        }
        return message;
      });

      return { ...session, combinedMessages }; // Return session with combined messages
    };

    // Process each session
    const processedSessions = await Promise.all(
      sessions.map(session => processSessionMessages(session))
    );

    dispatch(fetchPreviousMessagesSuccess(processedSessions));
  } catch (error) {
    console.error('Error fetching previous messages:', error);
    dispatch(fetchPreviousMessagesError(storableError(error)));
  }
};

export const initiateChatSession = params => async dispatch => {
  // Dispatch the request action to indicate the process has started
  dispatch(initiateChatSessionRequest());
  try {
    // Perform the async operation to initiate a chat session
    const { data, error } = await supabase.from('chatsessions').insert(params);
    // Dispatch the success action if the chat session is initiated successfully
    dispatch(initiateChatSessionSuccess(data));
  } catch (error) {
    // Dispatch the error action if something goes wrong
    dispatch(initiateChatSessionError(storableError(error)));
  }
};
// Fetch a specific chat session by ID
export const fetchChatSession = chatSessionId => async dispatch => {
  dispatch(fetchChatSessionRequest());

  try {
    const { data, error } = await supabase
      .from('chatsessions')
      .select('*')
      .eq('id', chatSessionId); // Fetch specific chat session by ID

    if (error) {
      throw error;
    }

    if (isArrayLength(data)) {
      dispatch(fetchChatSessionSuccess(data));
    }
  } catch (error) {
    dispatch(fetchChatSessionError(storableError(error)));
  }
};

// Fetch all chat sessions for the current user
export const fetchAllChatSessions = currentUserId => async (dispatch, getState) => {
  dispatch(fetchChatSessionRequest());
  try {
    // Perform the async operation to fetch all chat sessions for the user
    const { data, error } = await supabase
      .from('chatsessions')
      .select('*')
      .eq('user_id', currentUserId); // Filter by the current user's ID

    if (error) {
      throw error;
    }
    if (isArrayLength(data)) {
      dispatch(fetchChatSessionSuccess(data)); // Dispatch the success with all sessions
      dispatch(fetchPreviousMessages(data)); // Load messages for the specific session
      // Optionally, handle further actions with the fetched sessions here
    }
  } catch (error) {
    dispatch(fetchChatSessionError(storableError(error)));
  }
};

export const loadData = (params, search, config) => async dispatch => {
  const chatSessionId = params?.id;
  const currentUser = await dispatch(fetchCurrentUser());
  const currentUserId = currentUser?.id?.uuid;
  if (currentUserId) {
    // Fetch all chat sessions when no specific ID is provided
    dispatch(fetchAllChatSessions(currentUserId));
  }
  // Clear old line-items
  return currentUser;
};
