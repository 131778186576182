import { verifyVoucherCode } from '../util/api';

export const VERIFY_COUPON_CODE_REQUEST = 'app/global/VERIFY_COUPON_CODE_REQUEST';
export const VERIFY_COUPON_CODE_SUCCESS = 'app/global/VERIFY_COUPON_CODE_SUCCESS';
export const VERIFY_COUPON_CODE_ERROR = 'app/global/VERIFY_COUPON_CODE_ERROR';
export const CLEAR_VERIFY_COUPON_CODE_STATES = 'app/global/CLEAR_VERIFY_COUPON_CODE_STATES';

const initialState = {
  verifyCodeRequest: false,
  verifyCodeSuccess: false,
  verifyCodeError: false,
  isContentVoucher: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_COUPON_CODE_REQUEST:
      return {
        ...state,
        verifyCodeRequest: true,
      };
    case VERIFY_COUPON_CODE_SUCCESS:
      return {
        ...state,
        verifyCodeRequest: false,
        isContentVoucher: payload.isContentVoucher,
        verifyCodeSuccess: payload.isCodeValid,
        couponCode: payload.couponCode,
        couponId: payload.couponId,
      };
    case VERIFY_COUPON_CODE_ERROR:
      return {
        ...state,
        verifyCodeRequest: false,
        verifyCodeError: true,
      };
    case CLEAR_VERIFY_COUPON_CODE_STATES:
      return {
        ...state,
        verifyCodeRequest: false,
        verifyCodeSuccess: false,
        verifyCodeError: false,
        isContentVoucher: false,
      };
    default:
      return state;
  }
}

export const verifyCouponCodeRequest = () => ({
  type: VERIFY_COUPON_CODE_REQUEST,
});

export const verifyCouponCodeSuccess = payload => ({
  type: VERIFY_COUPON_CODE_SUCCESS,
  payload,
});

export const verifyCouponCodeError = payload => ({
  type: VERIFY_COUPON_CODE_ERROR,
  payload,
});

export const clearCouponCodeInitialStates = payload => ({
  type: CLEAR_VERIFY_COUPON_CODE_STATES,
  payload,
});

// ================ Selectors ================ //

export const verifyCodeSelector = state => {
  return {
    inProgress: state.global.verifyCodeRequest,
    success: state.global.verifyCodeSuccess,
    error: state.global.verifyCodeError,
    isContentVoucher: state.global.isContentVoucher,
    couponCode: state.global.couponCode,
    couponId: state.global.couponId,
  };
};

// ================ Actions ================ //

export const verifyCouponCode = params => async (dispatch, getState, sdk) => {
  dispatch(verifyCouponCodeRequest());
  try {
    const { isCodeValid = false, isContentVoucher = null, couponId } =
      (await verifyVoucherCode({ ...params })) || {};

    dispatch(
      verifyCouponCodeSuccess({ isCodeValid, isContentVoucher, couponCode: params?.code, couponId })
    );
    return { isCodeValid, isContentVoucher, couponId };
  } catch (error) {
    dispatch(verifyCouponCodeError(error.message || 'Error while verifying coupon code'));
    console.log(error, 'Error while verifying coupon code');
    return error;
  }
};
