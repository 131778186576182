import {
  fetchTagsFilter,
  fetchTutorials,
  getEvent,
  getEvents,
  getEventTags,
  getPopularItems,
  getProducts,
  getProductStages,
  getProductTypes,
  getSymptoms,
  getSymptomsCategories,
  getTheBasics,
  getTopicDetails,
  getTopics,
  getTutorialSubcategories,
} from '../util/api';
import { getStrapiLocale, isArrayLength } from '../util/genericHelpers';

const defaultLocale = getStrapiLocale() || 'el-GR';

export const FETCH_TUTORIALS_REQUEST = 'app/strapi/FETCH_TUTORIALS_REQUEST';
export const FETCH_TUTORIALS_SUCCESS = 'app/strapi/FETCH_TUTORIALS_SUCCESS';
export const FETCH_TUTORIALS_ERROR = 'app/strapi/FETCH_TUTORIALS_ERROR';

export const CLEAR_TUTORIALS = 'app/strapi/CLEAR_TUTORIALS';

export const FETCH_EVENTS_REQUEST = 'app/strapi/FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'app/strapi/FETCH_EVENTS_SUCCESS';

// Single Event Detail
export const FETCH_EVENT_REQUEST = 'app/strapi/FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'app/strapi/FETCH_EVENT_SUCCESS';

export const FETCH_TOPIC_REQUEST = 'app/strapi/FETCH_TOPIC_REQUEST';
export const FETCH_TOPIC_SUCCESS = 'app/strapi/FETCH_TOPIC_SUCCESS';
export const FETCH_TOPIC_DETAILS_REQUEST = 'app/strapi/FETCH_TOPIC_DETAILS_REQUEST';
export const FETCH_TOPIC_DETAILS_SUCCESS = 'app/strapi/FETCH_TOPIC_DETAILS_SUCCESS';

export const FETCH_TUTORIALS_SUBCATEGORIES_REQUEST =
  'app/strapi/FETCH_TUTORIALS_SUBCATEGORIES_REQUEST';
export const FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS =
  'app/strapi/FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS';
export const FETCH_SYMPTOMS_SUCCESS = 'app/strapi/FETCH_SYMPTOMS_SUCCESS';

export const FETCH_THE_BASICS_REQUEST = 'app/strapi/FETCH_THE_BASICS_REQUEST';
export const FETCH_THE_BASICS_SUCCESS = 'app/strapi/FETCH_THE_BASICS_SUCCESS';
export const FETCH_THE_BASICS_ERROR = 'app/strapi/FETCH_THE_BASICS_ERROR';

export const FETCH_SYMPTOM_BY_SLUG_REQUEST = 'app/strapi/FETCH_SYMPTOM_BY_SLUG_REQUEST';
export const FETCH_SYMPTOM_BY_SLUG_SUCCESS = 'app/strapi/FETCH_SYMPTOM_BY_SLUG_SUCCESS';
export const FETCH_SYMPTOM_BY_SLUG_ERROR = 'app/strapi/FETCH_SYMPTOM_BY_SLUG_ERROR';

export const FETCH_SYMPTOM_CATEGORIES_REQUEST = 'app/strapi/FETCH_SYMPTOM_CATEGORIES_REQUEST';
export const FETCH_SYMPTOM_CATEGORIES_SUCCESS = 'app/strapi/FETCH_SYMPTOM_CATEGORIES_SUCCESS';
export const FETCH_SYMPTOM_CATEGORIES_ERROR = 'app/strapi/FETCH_SYMPTOM_CATEGORIES_ERROR';

export const FETCH_EVENT_TAGS_REQUEST = 'app/strapi/FETCH_EVENT_TAGS_REQUEST';
export const FETCH_EVENT_TAGS_SUCCESS = 'app/strapi/FETCH_EVENT_TAGS_SUCCESS';
export const FETCH_EVENT_TAGS_ERROR = 'app/strapi/FETCH_EVENT_TAGS_ERROR';

export const FETCH_POPULAR_REQUEST = 'app/strapi/FETCH_POPULAR_REQUEST';
export const FETCH_POPULAR_SUCCESS = 'app/strapi/FETCH_POPULAR_SUCCESS';
export const FETCH_POPULAR_ERROR = 'app/strapi/FETCH_POPULAR_ERROR';

export const FETCH_PRODUCTS_REQUEST = 'app/strapi/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'app/strapi/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'app/strapi/FETCH_PRODUCTS_ERROR';

export const FETCH_PRODUCT_STAGES_REQUEST = 'app/strapi/FETCH_PRODUCT_STAGES_REQUEST';
export const FETCH_PRODUCT_STAGES_SUCCESS = 'app/strapi/FETCH_PRODUCT_STAGES_SUCCESS';
export const FETCH_PRODUCT_STAGES_ERROR = 'app/strapi/FETCH_PRODUCT_STAGES_ERROR';

export const FETCH_PRODUCT_TYPES_REQUEST = 'app/strapi/FETCH_PRODUCT_TYPES_REQUEST';
export const FETCH_PRODUCT_TYPES_SUCCESS = 'app/strapi/FETCH_PRODUCT_TYPES_SUCCESS';
export const FETCH_PRODUCT_TYPES_ERROR = 'app/strapi/FETCH_PRODUCT_TYPES_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTutorialsInProgress: false,
  tutorials: [],
  module: [],
  fetchTutorialsError: false,
  event: null,
  fetchEventInProgress: false,
  events: [],
  writtenGuides: [],
  fetchEventsInProgress: false,
  topics: [],
  basicTopics: [],
  topicDetails: [],
  fetchTopicDetailsInProgress: false,
  fetchTutorialsSubcategoriesInProgress: false,
  tutorialsSubcategories: [],
  symptoms: [],
  fetchTopicsInProgress: false,
  fetchTopicsError: null,
  fetchSymptomInProgress: false,
  fetchSymptomError: null,
  symptomCategories: [],
  fetchSymptomCategoriesInProgress: false,
  fetchSymptomCategoriesError: null,
  fetchPopularInProgress: false,
  popularTutorials: [],
  popularTopics: [],
  fetchPopularError: null,
  products: [],
  productStages: [],
  productTypes: [],
  fetchProductsInProgress: false,
  fetchProductsError: false,
  fetchProductStagesInProgress: false,
  fetchProductStagesError: false,
  fetchProductTypesInProgress: false,
  fetchProductTypesError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SYMPTOM_BY_SLUG_REQUEST:
      return {
        ...state,
        fetchSymptomInProgress: true,
      };
    case FETCH_SYMPTOM_BY_SLUG_SUCCESS:
      return {
        ...state,
        symptoms: payload,
        fetchSymptomInProgress: false,
      };
    case FETCH_SYMPTOM_BY_SLUG_ERROR:
      return {
        ...state,
        fetchSymptomInProgress: false,
        fetchSymptomError: true,
      };
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        fetchProductsInProgress: true,
        fetchProductsError: false,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload,
        fetchProductsInProgress: false,
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        fetchProductsInProgress: false,
        fetchProductsError: true,
      };

    case FETCH_PRODUCT_STAGES_REQUEST:
      return {
        ...state,
        fetchProductStagesInProgress: true,
        fetchProductStagesError: false,
      };
    case FETCH_PRODUCT_STAGES_SUCCESS:
      return {
        ...state,
        productStages: payload,
        fetchProductStagesInProgress: false,
      };
    case FETCH_PRODUCT_STAGES_ERROR:
      return {
        ...state,
        fetchProductStagesInProgress: false,
        fetchProductStagesError: true,
      };

    case FETCH_PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        fetchProductTypesInProgress: true,
        fetchProductTypesError: false,
      };
    case FETCH_PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        productTypes: payload,
        fetchProductTypesInProgress: false,
      };
    case FETCH_PRODUCT_TYPES_ERROR:
      return {
        ...state,
        fetchProductTypesInProgress: false,
        fetchProductTypesError: true,
      };

    case FETCH_THE_BASICS_REQUEST:
      return {
        ...state,
        fetchTopicsInProgress: true,
      };
    case FETCH_THE_BASICS_SUCCESS:
      return {
        ...state,
        basicTopics: payload,
        fetchTopicsInProgress: false,
      };
    case FETCH_THE_BASICS_ERROR:
      return {
        ...state,
        fetchTopicsInProgress: false,
        fetchTopicsError: true,
      };
    case FETCH_SYMPTOMS_SUCCESS:
      return {
        ...state,
        symptoms: payload,
      };
    case FETCH_POPULAR_REQUEST:
      return {
        ...state,
        fetchPopularInProgress: true,
        fetchPopularError: null,
      };
    case FETCH_POPULAR_SUCCESS:
      return {
        ...state,
        fetchPopularInProgress: false,
        popularTutorials: payload?.tutorials || [],
        popularTopics: payload?.topics || [],
      };
    case FETCH_POPULAR_ERROR:
      return {
        ...state,
        fetchPopularInProgress: false,
        fetchPopularError: true,
      };
    case FETCH_TUTORIALS_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        fetchTutorialsSubcategoriesInProgress: true,
      };
    case FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        tutorialsSubcategories: payload,
        fetchTutorialsSubcategoriesInProgress: false,
      };
    case FETCH_TOPIC_REQUEST:
      return {
        ...state,
        fetchWrittenGuidesInProgress: true,
      };
    case FETCH_TOPIC_SUCCESS:
      return {
        ...state,
        writtenGuides: payload,
        fetchWrittenGuidesInProgress: false,
      };
    case FETCH_TOPIC_DETAILS_REQUEST:
      return {
        ...state,
        fetchTopicDetailsInProgress: true,
      };
    case FETCH_TOPIC_DETAILS_SUCCESS:
      return {
        ...state,
        topicDetails: payload,
        fetchTopicDetailsInProgress: false,
      };
    case FETCH_EVENT_REQUEST:
      return {
        ...state,
        fetchEventInProgress: true,
      };
    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        event: payload,
        fetchEventInProgress: false,
      };
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        fetchEventsInProgress: true,
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        fetchEventsInProgress: false,
        events: payload,
      };
    case FETCH_TUTORIALS_REQUEST:
      return {
        ...state,
        fetchTutorialsInProgress: true,
      };
    case FETCH_TUTORIALS_SUCCESS:
      return {
        ...state,
        fetchTutorialsInProgress: false,
        tutorials: payload?.tutorials,
        module: payload?.module,
      };
    case FETCH_TUTORIALS_ERROR:
      return {
        ...state,
        fetchTutorialsInProgress: false,
        fetchTutorialsError: true,
      };
    case CLEAR_TUTORIALS:
      return {
        ...state,
        tutorials: [],
      };
    case FETCH_SYMPTOM_CATEGORIES_REQUEST:
      return {
        ...state,
        fetchSymptomCategoriesInProgress: true,
        fetchSymptomCategoriesError: false,
      };
    case FETCH_SYMPTOM_CATEGORIES_SUCCESS:
      return {
        ...state,
        symptomCategories: payload,
        fetchSymptomCategoriesInProgress: false,
      };
    case FETCH_SYMPTOM_CATEGORIES_ERROR:
      return {
        ...state,
        fetchSymptomCategoriesInProgress: false,
        fetchSymptomCategoriesError: true,
      };
    case FETCH_EVENT_TAGS_REQUEST:
      return {
        ...state,
        fetchEventTagsInProgress: true,
        fetchEventTagsError: false,
      };
    case FETCH_EVENT_TAGS_SUCCESS:
      return {
        ...state,
        eventTags: payload,
        fetchEventTagsInProgress: false,
      };
    case FETCH_EVENT_TAGS_ERROR:
      return {
        ...state,
        fetchEventTagsInProgress: false,
        fetchEventTagsError: true,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const fetchTutorialsData = state => {
  return { tutorials: state.strapi.tutorials, module: state.strapi.module };
};
export const fetchModulesData = state => {
  return state.strapi.module;
};

export const fetchTutorialsInProgress = state => {
  return state.strapi.fetchTutorialsInProgress;
};

export const fetchTutorialsErrors = state => {
  return state.strapi.fetchTutorialsError;
};

export const fetchEventsData = state => {
  return {
    events: state.strapi.events,
    inProgress: state.strapi.fetchEventsInProgress,
  };
};

export const fetchEventData = state => {
  return {
    event: state.strapi.event,
    inProgress: state.strapi.fetchEventInProgress,
  };
};

export const fetchTopicsData = state => {
  return {
    writtenGuides: state.strapi.writtenGuides,
    fetchWrittenGuidesInProgress: state.strapi.fetchWrittenGuidesInProgress,
  };
};

export const fetchTopicsDetailsData = state => {
  return {
    topicDetails: state.strapi.topicDetails,
    fetchTopicDetailsInProgress: state.strapi.fetchTopicDetailsInProgress,
  };
};

export const fetchTutorialsSubcategoriesData = state => {
  return {
    symptoms: state.strapi.symptoms,
    tutorialsSubcategories: state.strapi.tutorialsSubcategories,
    fetchTutorialsSubcategoriesInProgress: state.strapi.fetchTutorialsSubcategoriesInProgress,
  };
};

export const fetchTheBasicsData = state => {
  return {
    topics: state.strapi.basicTopics,
    fetchTopicsInProgress: state.strapi.fetchTopicsInProgress,
  };
};

export const fetchSymptomBySlugData = state => {
  return {
    symptoms: state.strapi.symptoms,
    fetchSymptomInProgress: state.strapi.fetchSymptomInProgress,
  };
};

export const fetchSymptomCategoriesData = state => {
  return {
    symptomCategories: state.strapi.symptomCategories,
    fetchSymptomCategoriesInProgress: state.strapi.fetchSymptomCategoriesInProgress,
    fetchSymptomCategoriesError: state.strapi.fetchSymptomCategoriesError,
  };
};

export const fetchEventTagsData = state => {
  return {
    eventTags: state.strapi.eventTags,
    fetchEventTagsInProgress: state.strapi.fetchEventTagsInProgress,
    fetchEventTagsError: state.strapi.fetchEventTagsError,
  };
};

// Selector to get all popular data (topics and tutorials)
export const fetchPopularData = state => ({
  popularTopics: state.strapi.popularTopics,
  popularTutorials: state.strapi.popularTutorials,
  fetchPopularInProgress: state.strapi.fetchPopularInProgress,
  fetchPopularError: state.strapi.fetchPopularError,
});

export const fetchProductsData = state => ({
  products: state.strapi.products,
  inProgress: state.strapi.fetchProductsInProgress,
  error: state.strapi.fetchProductsError,
});

export const fetchProductStagesData = state => ({
  productStages: state.strapi.productStages,
  inProgress: state.strapi.fetchProductStagesInProgress,
  error: state.strapi.fetchProductStagesError,
});

export const fetchProductTypesData = state => ({
  productTypes: state.strapi.productTypes,
  inProgress: state.strapi.fetchProductTypesInProgress,
  error: state.strapi.fetchProductTypesError,
});

// ================ Action creators ================ //

export const fetchProductsRequest = () => ({ type: FETCH_PRODUCTS_REQUEST });
export const fetchProductsSuccess = payload => ({ type: FETCH_PRODUCTS_SUCCESS, payload });
export const fetchProductsError = () => ({ type: FETCH_PRODUCTS_ERROR });

export const fetchProductStagesRequest = () => ({ type: FETCH_PRODUCT_STAGES_REQUEST });
export const fetchProductStagesSuccess = payload => ({
  type: FETCH_PRODUCT_STAGES_SUCCESS,
  payload,
});
export const fetchProductStagesError = () => ({ type: FETCH_PRODUCT_STAGES_ERROR });

export const fetchProductTypesRequest = () => ({ type: FETCH_PRODUCT_TYPES_REQUEST });
export const fetchProductTypesSuccess = payload => ({ type: FETCH_PRODUCT_TYPES_SUCCESS, payload });
export const fetchProductTypesError = () => ({ type: FETCH_PRODUCT_TYPES_ERROR });

export const fetchEventTagsRequest = () => ({
  type: FETCH_EVENT_TAGS_REQUEST,
});

export const fetchEventTagsSuccess = payload => ({
  type: FETCH_EVENT_TAGS_SUCCESS,
  payload,
});

export const fetchEventTagsError = () => ({
  type: FETCH_EVENT_TAGS_ERROR,
});

export const fetchSymptomBySlugRequest = () => ({
  type: FETCH_SYMPTOM_BY_SLUG_REQUEST,
});

export const fetchSymptomBySlugSuccess = payload => ({
  type: FETCH_SYMPTOM_BY_SLUG_SUCCESS,
  payload,
});

export const fetchSymptomBySlugError = () => ({
  type: FETCH_SYMPTOM_BY_SLUG_ERROR,
});

export const fetchPopularRequest = () => ({
  type: FETCH_POPULAR_REQUEST,
});

export const fetchPopularSuccess = payload => ({
  type: FETCH_POPULAR_SUCCESS,
  payload,
});

export const fetchPopularError = () => ({
  type: FETCH_POPULAR_ERROR,
});

export const fetchTheBasicsRequest = () => ({
  type: FETCH_THE_BASICS_REQUEST,
});

export const fetchTheBasicsSuccess = payload => ({
  type: FETCH_THE_BASICS_SUCCESS,
  payload,
});

export const fetchTheBasicsError = () => ({
  type: FETCH_THE_BASICS_ERROR,
});

export const fetchTutorialsRequest = () => ({
  type: FETCH_TUTORIALS_REQUEST,
});

export const fetchTutorialsSuccess = payload => ({
  type: FETCH_TUTORIALS_SUCCESS,
  payload,
});

export const fetchTutorialsError = () => ({
  type: FETCH_TUTORIALS_ERROR,
});

export const clearTutorials = () => ({
  type: CLEAR_TUTORIALS,
});

export const fetchEventsRequest = () => ({
  type: FETCH_EVENTS_REQUEST,
});

export const fetchEventsSuccess = payload => ({
  type: FETCH_EVENTS_SUCCESS,
  payload,
});

export const fetchEventRequest = () => ({
  type: FETCH_EVENT_REQUEST,
});

export const fetchEventSuccess = payload => ({
  type: FETCH_EVENT_SUCCESS,
  payload,
});

export const fetchTopicsRequest = () => ({
  type: FETCH_TOPIC_REQUEST,
});

export const fetchTopicsSuccess = payload => ({
  type: FETCH_TOPIC_SUCCESS,
  payload,
});
export const fetchTopicsDetailsRequest = () => ({
  type: FETCH_TOPIC_DETAILS_REQUEST,
});

export const fetchTopicsDetailsSuccess = payload => ({
  type: FETCH_TOPIC_DETAILS_SUCCESS,
  payload,
});

export const fetchTutorialSubcategoriesRequest = () => ({
  type: FETCH_TUTORIALS_SUBCATEGORIES_REQUEST,
});

export const fetchTutorialSubcategoriesSuccess = payload => ({
  type: FETCH_TUTORIALS_SUBCATEGORIES_SUCCESS,
  payload,
});

export const fetchSymptomsSuccess = payload => ({
  type: FETCH_SYMPTOMS_SUCCESS,
  payload,
});

export const fetchSymptomCategoriesRequest = () => ({
  type: FETCH_SYMPTOM_CATEGORIES_REQUEST,
});

export const fetchSymptomCategoriesSuccess = payload => ({
  type: FETCH_SYMPTOM_CATEGORIES_SUCCESS,
  payload,
});

export const fetchSymptomCategoriesError = () => ({
  type: FETCH_SYMPTOM_CATEGORIES_ERROR,
});

// ================ Thunks ================ //

export const fetchVideoTutorials = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTutorialsRequest());

  try {
    const tutorialsResponse = await fetchTutorials({ defaultLocale, ...params });
    dispatch(fetchTutorialsSuccess(tutorialsResponse));
  } catch (error) {
    dispatch(fetchTutorialsError());
  }
};

export const fetchEvents = params => async (dispatch, getState, sdk) => {
  dispatch(fetchEventsRequest());
  try {
    const { events = {} } = (await getEvents({ defaultLocale, ...params })) || {};
    dispatch(fetchEventsSuccess(events));
    return events;
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchEventTags = params => async (dispatch, getState, sdk) => {
  dispatch(fetchEventTagsRequest());
  try {
    const { eventTags = [] } = (await getEventTags({ defaultLocale, ...params })) || {};
    dispatch(fetchEventTagsSuccess(eventTags));
    return eventTags;
  } catch (error) {
    console.error('Error while fetching event tags:', error);
    dispatch(fetchEventTagsError());
  }
};

export const fetchEvent = params => async (dispatch, getState, sdk) => {
  dispatch(fetchEventRequest());
  try {
    const { event = [] } = (await getEvent({ defaultLocale, ...params })) || {};
    dispatch(fetchEventSuccess(isArrayLength(event) && event[0]));
    return event;
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchTopics = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTopicsRequest());
  try {
    const { topics = [] } = (await getTopics({ defaultLocale, ...params })) || {};
    dispatch(fetchTopicsSuccess(topics));
    return topics;
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchTopicDetails = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTopicsDetailsRequest());
  try {
    const { topics = [] } = (await getTopicDetails({ defaultLocale, ...params })) || {};
    dispatch(fetchTopicsDetailsSuccess(topics));
    return topics;
  } catch (error) {
    console.log(error, 'Error while fetching topic details');
  }
};

export const fetchTheBasics = (params = {}) => async (dispatch, getState, sdk) => {
  dispatch(fetchTheBasicsRequest());
  try {
    const { topics = [] } = (await getTheBasics({ defaultLocale, ...params })) || {};
    dispatch(fetchTheBasicsSuccess(topics));
    return topics;
  } catch (error) {
    console.log(error, 'Error while fetching the basics');
    dispatch(fetchTheBasicsError());
  }
};

export const fetchTutorialSubcategories = params => async (dispatch, getState, sdk) => {
  dispatch(fetchTutorialSubcategoriesRequest());
  try {
    const [tutorialsResponse, symptomsResponse] = await Promise.all([
      getTutorialSubcategories({ defaultLocale, ...params }),
      getSymptoms({ defaultLocale, ...params }),
    ]);

    const tutorials = tutorialsResponse?.tutorials || [];
    const symptoms = symptomsResponse?.symptoms || [];

    dispatch(fetchTutorialSubcategoriesSuccess(tutorials));
    dispatch(fetchSymptomsSuccess(symptoms));
    return tutorials;
  } catch (error) {
    console.error('Error while fetching tutorials subcategories:', error);
    // dispatch(fetchTutorialSubcategoriesFailure(error));
    return [];
  }
};

export const fetchSymptomBySlug = params => async (dispatch, getState, sdk) => {
  dispatch(fetchSymptomBySlugRequest());
  try {
    const { symptoms = [] } = (await getSymptoms({ defaultLocale, ...params })) || {};
    dispatch(fetchSymptomBySlugSuccess(symptoms));
    return symptoms;
  } catch (error) {
    console.error(error, 'Error while fetching symptom by slug');
    dispatch(fetchSymptomBySlugError());
  }
};

export const fetchSymptomCategories = params => async (dispatch, getState, sdk) => {
  dispatch(fetchSymptomCategoriesRequest());
  try {
    const { symptoms = [] } = (await getSymptomsCategories({ defaultLocale, ...params })) || {};
    dispatch(fetchSymptomCategoriesSuccess(symptoms));
    return symptoms;
  } catch (error) {
    console.error('Error while fetching symptom categories:', error);
    dispatch(fetchSymptomCategoriesError());
  }
};

export const fetchPopularContent = () => async (dispatch, getState, sdk) => {
  dispatch(fetchPopularRequest());
  try {
    const response = await getPopularItems({
      defaultLocale,
    });

    const popularTopics = response?.popularTopics || [];
    const popularTutorials = response?.popularTutorials || [];

    dispatch(fetchPopularSuccess({ topics: popularTopics, tutorials: popularTutorials }));
  } catch (error) {
    console.error('Error while fetching popular content:', error);
    dispatch(fetchPopularError());
  }
};

export const fetchProducts = params => async (dispatch, getState, sdk) => {
  dispatch(fetchProductsRequest());
  try {
    const { products = [] } = (await getProducts({ defaultLocale, ...params })) || {};
    console.log(products)
    dispatch(fetchProductsSuccess(products));
    return products;
  } catch (error) {
    console.error('Error while fetching products:', error);
    dispatch(fetchProductsError());
  }
};

export const fetchProductStages = params => async (dispatch, getState, sdk) => {
  dispatch(fetchProductStagesRequest());
  try {
    const { productStages = [] } = (await getProductStages({ defaultLocale, ...params })) || {};
    dispatch(fetchProductStagesSuccess(productStages));
    return productStages;
  } catch (error) {
    console.error('Error while fetching product stages:', error);
    dispatch(fetchProductStagesError());
  }
};

export const fetchProductTypes = params => async (dispatch, getState, sdk) => {
  dispatch(fetchProductTypesRequest());
  try {
    const { productTypes = [] } = (await getProductTypes({ defaultLocale, ...params })) || {};
    dispatch(fetchProductTypesSuccess(productTypes));
    return productTypes;
  } catch (error) {
    console.error('Error while fetching product types:', error);
    dispatch(fetchProductTypesError());
  }
};
